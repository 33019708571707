<script>
import {required, email} from "vuelidate/lib/validators";

import {
    authMethods,
    authFackMethods,
    notificationMethods
} from "@/state/helpers";

export default {
    data() {
        return {
            email: "",
            password: "",
            submitted: false,
            isAuthError: false
        };
    },
    computed: {
        notification() {
            return this.$store ? this.$store.state.notification : null;
        }
    },
    created() {
        document.body.classList.add("auth-body-bg");
    },
    validations: {
        email: {required, email},
        password: {required}
    },
    methods: {
        ...authMethods,
        ...authFackMethods,
        ...notificationMethods,
        // Try to log the user in with the username
        // and password they provided.
        tryToLogIn() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            } else {

                this.tryingToLogIn = true;
                // Reset the authError if it existed.
                this.authError = null;
                return (
                    this.logIn({
                        email: this.email,
                        password: this.password
                    })
                        // eslint-disable-next-line no-unused-vars
                        .then(token => {
                            this.tryingToLogIn = false;
                            this.isAuthError = false;
                            // Redirect to the originally requested page, or to the home page
                            this.$router.push(
                                this.$route.query.redirectFrom || {name: "home"}
                            );
                        })
                        .catch(error => {
                            console.log('error',error);
                            this.tryingToLogIn = false;
                            this.authError = error ? error : "";
                            this.isAuthError = true;
                        })
                );

            }
        }
    }
};
</script>

<template>
    <div>
        <div>
            <div class="container-fluid p-0">
                <div class="row no-gutters">
                    <div class="col-lg-4">
                        <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                            <div class="w-100">
                                <div class="row justify-content-center">
                                    <div class="col-lg-9">
                                        <div>
                                            <div class="text-center">
                                                <div>
                                                    <a href="/" class="logo">
                                                        <img src="@/assets/logo-abcomm.png" alt="logo"/>
                                                    </a>
                                                </div>

                                                <h4 class="font-size-18 mt-4">Entrar</h4>
                                            </div>

                                            <b-alert
                                                variant="danger"
                                                class="mt-3"
                                                v-if="notification.message"
                                                show
                                                dismissible
                                            >{{ notification.message }}
                                            </b-alert>

                                            <div class="p-2 mt-5">
                                                <form class="form-horizontal" @submit.prevent="tryToLogIn">
                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-mail-line auti-custom-input-icon"></i>
                                                        <label for="email">Email</label>
                                                        <input
                                                            type="email"
                                                            v-model="email"
                                                            class="form-control"
                                                            id="email"
                                                            :class="{ 'is-invalid': submitted && $v.email.$error }"
                                                        />
                                                        <div v-if="submitted && $v.email.$error" class="invalid-feedback">
                                                            <span v-if="!$v.email.required">E-mail é obrigatório.</span>
                                                            <span v-if="!$v.email.email">E-mail é obrigatório.</span>
                                                        </div>
                                                        <div
                                                            v-if="isAuthError"
                                                            class="invalid-feedback" style="display: block"
                                                        >Erro no login, tente novamente
                                                        </div>
                                                    </div>

                                                    <div class="form-group auth-form-group-custom mb-4">
                                                        <i class="ri-lock-2-line auti-custom-input-icon"></i>
                                                        <label for="userpassword">Senha</label>
                                                        <input
                                                            v-model="password"
                                                            type="password"
                                                            class="form-control"
                                                            id="userpassword"
                                                            :class="{ 'is-invalid': submitted && $v.password.$error }"
                                                        />
                                                        <div
                                                            v-if="submitted && !$v.password.required"
                                                            class="invalid-feedback"
                                                        >Senha é obrigatória.
                                                        </div>
                                                        <div
                                                            v-if="isAuthError"
                                                            class="invalid-feedback" style="display: block"
                                                        >Erro no login, tente novamente
                                                        </div>
                                                    </div>

                                                    <div class="custom-control custom-checkbox">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="customControlInline"
                                                        />
                                                        <label
                                                            class="custom-control-label"
                                                            for="customControlInline"
                                                        >Lembrar-me</label>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        <button
                                                            class="btn btn-primary w-md waves-effect waves-light"
                                                            type="submit"
                                                        >Login
                                                        </button>
                                                    </div>

                                                    <div class="mt-4 text-center">
                                                        <router-link tag="a" to="/forgot-password" class="text-muted">
                                                            <i class="mdi mdi-lock mr-1"></i> Esqueceu a sua senha?

                                                        </router-link>
                                                    </div>
                                                </form>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="authentication-bg">
                            <div class="bg-overlay"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>